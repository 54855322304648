import React from 'react'

import cards from "../data/Cards.json"

export default function 
() {
  return (
    <>
         <section className="app-price section-padding">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">

                        <div className="sec-head  text-center">
                            <h6 className="wow fadeIn" data-wow-delay=".5s"> Pricing Package</h6>
                            <h3 className="wow color-font">Friendly Pricing Packages </h3>

                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10">

                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                                    aria-controls="home" aria-selected="true">Custom logo design</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                                    aria-controls="profile" aria-selected="false">Custom Video Animation</a>
                            </li>

                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div className="pric-tables">
                                    <div className="row justify-content-center ">
                                    
                                        <div className="col-lg-4 col-md-5">
                                            <div className="item active">
                                                <div className="type text-center mb-15">
                                                    <h5>Logo Special<br className="d-none d-lg-block"/>
                                                        </h5>
                                                </div>
                                                <div className="amount text-center mb-40">
                                                    <h3><span>$</span> 59</h3>
                                                </div>
                                                <div className="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/14k5kAbLE7IBef65kp" target="_blank" className="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div className="feat">

                                                    <ul className="scrollbar_pricing">

                                                        <li>
                                                            <div>
                                                                <i className="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>4</strong> Original Logo Concepts</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 
                                                            <strong>2</strong> Dedicated Logo Designer
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>4</strong> Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>With</strong>  Grey Scale Format 
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>Free</strong>  Icon Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>Formats:</strong>  JPEG Only </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>24 - 48</strong>Business Hours Turn Around Time </div>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <div className="item active">
                                                <div className="type text-center mb-15">
                                                    <h5>Logo Plus <br className="d-none d-lg-block"/>
                                                        </h5>
                                                </div>
                                                <div className="amount text-center mb-40">
                                                    <h3><span>$</span>199</h3>
                                                </div>
                                                <div className="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/bIY6oEcPI1kdgnefZ4" target="_blank" className="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div className="feat">

                                                    <ul className="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>12</strong> Original Logo Concepts </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div><strong> 4</strong> Dedicated Logo Designer (Industry Specific) </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Unlimited Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong></strong> Unlimited Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>Stationery</strong>  Design (Business Card, Letterhead, Envelope) </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>Email</strong> Signature Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong> With</strong> Grey Scale Format </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong> Formats:</strong>  JPEG, PSD, EPS, AI, PNG, TIFF, SVG</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong> 24 - 48</strong> Business Hours Turn Around Time</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> MORE FEATURES</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Satisfaction</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 100% Ownership Rights</div>
                                                        </li>
                                                    </ul>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <div className="item active">
                                                <div className="type text-center mb-15">
                                                    <h5>Logo Platinum <br className="d-none d-lg-block"/></h5>
                                                </div>
                                                <div className="amount text-center mb-40">
                                                    <h3><span>$</span> 399 </h3>
                                                </div>
                                                <div className="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/7sIeVa170d2V2wo14g" target="_blank" className="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div className="feat">
                                                    <ul className="scrollbar_pricing">
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Unlimited Original Logo Concepts</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 8 Dedicated Logo Designer (Industry Specific)</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Unlimited Revisions</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Stationery Design (Business Card, Letterhead, Envelope)</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 500 Business Cards</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Email Signature Design</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> With Grey Scale Format</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Free Icon Design</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 24 - 48 Business Hours Turn Around Time</li>
                                                        
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> <span>MORE FEATURES</span></li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 100% Satisfaction</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 100% Ownership Rights</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Money Back Guarantee</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Dedicated Account Manager</li>
                                                        </ul>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <div className="item active">
                                                <div className="type text-center mb-15">
                                                    <h5>Logo Infinite <br className="d-none d-lg-block"/></h5>
                                                </div>
                                                <div className="amount text-center mb-40">
                                                    <h3><span>$</span> 1249 </h3>
                                                </div>
                                                <div className="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/7sIeVa170d2V2wo14g" target="_blank" className="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div className="feat">

                                                    <ul className="scrollbar_pricing">
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Unlimited Original Logo Concepts </li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 12  Dedicated Logo Designer (Industry Specific) </li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Unlimited Revisions </li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 5 Page Informative Website </li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Stationery Design (Business Card, Letterhead, Envelope) </li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Brand Book</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 500 Business Cards</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 500 Letterheads</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Email Signature Design</li>
                                                         <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Social Media Designs (Facebook, Twitter, Instagram)</li>
                                                         <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 2 Sided Flyer OR Bi-Fold Brochure Design</li>
                                                         <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> With Grey Scale Format </li>
                                                         <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Free Icon Design </li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Formats: JPEG, PSD, EPS, AI, PNG, TIFF, SVG</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 24 - 48 Business Hours Turn Around Time</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> <span>MORE FEATURES</span></li>
                                                         <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 100% Satisfaction </li>
                                                         <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 100% Ownership Rights </li>
                                                         <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Money Back Guarantee </li>
                                                         <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Dedicated Account Manager </li>
                                                    </ul>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <div className="item active">
                                                <div className="type text-center mb-15">
                                                    <h5>Mascot Logo <br className="d-none d-lg-block"/></h5>
                                                </div>
                                                <div className="amount text-center mb-40">
                                                    <h3><span>$</span> 599 </h3>
                                                </div>
                                                <div className="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/7sIeVa170d2V2wo14g" target="_blank" className="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div className="feat">
                                                    
                                                    <ul className="scrollbar_pricing">
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 2 Mascot Logo Concepts</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Unlimited Revisions </li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Hand drawn Sketch</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Presentation on mockup</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 24 - 48 Business Hours Turn Around Time</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> File Formats: Ai,Eps,Png,Jpeg,pdf</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> <span>MORE FEATURES</span></li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 100% Satisfaction</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 100% Ownership Rights</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Money Back Guarantee</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Dedicated Account Manager</li>
                                                        </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                            <div className="item active">
                                                <div className="type text-center mb-15">
                                                    <h5>3D Logo<br className="d-none d-lg-block"/></h5>
                                                </div>
                                                <div className="amount text-center mb-40">
                                                    <h3><span>$</span> 349</h3>
                                                </div>
                                                <div className="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/7sIeVa170d2V2wo14g" target="_blank" className="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div className="feat">


                                                    <ul className="scrollbar_pricing">
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 3 Unique 3D Logo Concepts</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Light Effects and VFX</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Fully Rendered</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> Multiple 3D Angles</li>
                                                        
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 3 Dedicated  Designers</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 72 Business Hours Turn Around Time</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> UNLIMITED Revisions</li>
                                                        
                                                        
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> <span>MORE FEATURES</span></li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 100% Ownership Rights</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 100% Satisfaction Guarantee</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 100% Unique Design Guarantee</li>
                                                        <li> <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div> 100% Money Back Guarantee*</li>
                                                        </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="pric-tables">
                                    <div className="pric-tables">
                                        <div className="row ">
                                            <div className="col-lg-4 col-md-5">
                                                <div className="item active">
                                                    <div className="type text-center mb-15">
                                                        <h5>Startup Video
                                                            <br className="d-none d-lg-block"/>
                                                            Package
                                                        </h5>
                                                    </div>
                                                    <div className="amount text-center mb-40">
                                                        <h3><span>$</span> 209.99 </h3>
                                                    </div>
                                                    <div className="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/14k00g9Dw5At1skcNb" target="_blank" className="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>

                                                    <div className="feat">

                                                        <ul className="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 30 Second Video </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Storyboard </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice - Over &amp; Sound Effects </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Unlimited Revisions </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <div className="item active">
                                                    <div className="type text-center mb-15">
                                                        <h5>classNameic Video

                                                            <br className="d-none d-lg-block"/>
                                                            Package
                                                        </h5>
                                                    </div>
                                                    <div className="amount text-center mb-40">
                                                        <h3><span>$</span> 409.99 </h3>
                                                    </div>
                                                    <div className="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/9AQeVag1Ugf7ef6dRg" target="_blank" className="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div className="feat">
                                                        <ul className="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 60 Second Video </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Sample Theme </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                         </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Storyboard </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice - Over &amp; Sound Effects </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <div className="item active">
                                                    <div className="type text-center mb-15">
                                                        <h5>Premium Video <br className="d-none d-lg-block"/>
                                                            Package</h5>
                                                    </div>
                                                    <div className="amount text-center mb-40">
                                                        <h3><span>$</span> 809.99 </h3>
                                                    </div>
                                                    <div className="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/00g14k9Dwfb33As5kL" target="_blank" className="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div className="feat">
                                                        <ul className="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 90 Second Video </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Sample Theme </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Storyboard </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice - Over &amp; Sound Effects </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 6 Weeks Delivery </div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-lg-4 col-md-5">
                                                <div className="item active">
                                                    <div className="type text-center mb-15">
                                                        <h5>Unlimited Animation
                                                            <br className="d-none d-lg-block"/>
                                                            Package
                                                        </h5>
                                                    </div>
                                                    <div className="amount text-center mb-40">
                                                        <h3><span>$</span> 1409.99 </h3>
                                                    </div>
                                                    <div className="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/4gwbIYbLEfb3b2U9B2" target="_blank" className="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>

                                                    <div className="feat">
                                                        <ul className="scrollbar_pricing">
                                                         <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 120 Second Video </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Professional Script </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Sample Theme </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Storyboard </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Animation </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Voice - Over Sound Effects </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <div className="item active">
                                                    <div className="type text-center mb-15">
                                                        <h5>Professional 3D VFX

                                                            <br className="d-none d-lg-block"/>
                                                            Animation
                                                        </h5>
                                                    </div>
                                                    <div className="amount text-center mb-40">
                                                        <h3><span>$</span> 2009.99 </h3>
                                                    </div>
                                                    <div className="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/aEU14k7vo9QJ7QI8wZ" target="_blank" className="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div className="feat">
                                                        <ul className="scrollbar_pricing">
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 30s Duration - HD 1080 </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Unlimited Edits/Revisions &amp; Concepts </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Professional Script </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Concept and Storyboards </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 3D Modeling </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Texturing </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Rigging </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Animation </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Lighting </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Camera Setting </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Rendering </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Compositing and Special VFX </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Music and Foley </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Custom Setting, 2 Characters &amp; Graphics </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Animation Effects &amp; Visualization </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Voice Over - All accents (M/F) </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Editing and Final Output </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-5">
                                                <div className="item active">
                                                    <div className="type text-center mb-15">
                                                        <h5>Elite 3D VFX
                                                            <br className="d-none d-lg-block"/>
                                                            Animation
                                                        </h5>
                                                    </div>
                                                    <div className="amount text-center mb-40">
                                                        <h3><span>$</span> 2509.99 </h3>
                                                    </div>
                                                    <div className="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/fZeeVa6rkaUN7QI3cH" target="_blank" className="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div className="feat">

                                                        <ul className="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 60s Duration - HD 1080 </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Unlimited Edits/Revisions &amp; Concepts </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Concept and Storyboards </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 3D Modeling </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Texturing </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Rigging </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Lighting </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Camera Setting </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Rendering </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Compositing and Special VFX </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Music and Foley </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Custom Setting, 2 Characters &amp; Graphics </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation Effects &amp; Visualization </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice Over - All accents (M/F) </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Editing and Final Output </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                        </div>


                    </div>
                </div>
            </div>
        </section>

    </>
  )
}
