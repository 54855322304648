import axios from 'axios';
import React, { useState } from 'react'
import BasicModal from './Modal/Modal';

export default function Contact_from() {
    const [formData, setFormData] = useState({
        email: "",
        name: "",
        message: "",
      });
    
const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [message,setMessage] = useState({error:true,mes:""})
      
    
      const onChange = (event) =>{
        const {name,value} = event.target
        setFormData({...formData,[name] : value})
      }
    
    
      const handleSubmit = async () =>{
    
        const data = {
          name: formData.name,
          email: formData.email,
          message: formData.message,
        };
      
        try {
          if (formData.name && formData.email  && formData.message) {
            const response = await axios.post('https://theinceptives.com/Backend/simpleemail/', data);
            setMessage({...message,mes:"Form Contact Form Submitted Successfully",error:false})
            handleOpen()
          
        
       } else{
        setMessage({...message,mes:"Fill All The values in the Form",error:true})
        handleOpen()
      }
      
        } catch (error) {
          setMessage({...message,mes:"Fill All The values in the Form",error:true})
          console.error(error);
          handleOpen()
        };
      

  };

      

  return (
    <>
     <BasicModal open={open} handleOpen={handleOpen} setOpen={setOpen} setMessage={setMessage} message={message}/>
          <section class="contact section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form md-mb50">

                            <h4 class="fw-700 color-font mb-50">Get In Touch.</h4>

                            <form id="contact-form" 
                                >

                                <div class="messages"></div>

                                <div class="controls">

                                    <div class="form-group">
                                        <input id="form_name" style={{color:'black'}} type="text" name="name" value={formData.name} onChange={onChange} placeholder="Name"
                                            required="required"/>
                                    </div>

                                    <div class="form-group">
                                        <input id="form_email" style={{color:'black'}} type="email" name="email" value ={formData.email} onChange={onChange} placeholder="Email"
                                            required="required"/>
                                    </div>

                                    <div class="form-group">
                                        <textarea id="form_message" style={{color:'black'}} name="message" value={formData.message} onChange={onChange} placeholder="Message" rows="4"
                                            required="required"></textarea>
                                    </div>

                                </div>
                            </form>
                                    <button onClick={handleSubmit} class="butn bord curve mt-30 "><span>Send Message</span></button>
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 mt-3">
                        <div class="cont-info">
                            <h4 class="fw-700 color-font mb-50">Contact Info.</h4>
                            <h3 class="wow" data-splitting>Let's Talk.
                            </h3>
                            <div class="item mb-40">
                                <h5><a href="mailto:info@theinceptives.com">info@theinceptives.com</a></h5>
                                <h5><a href="tel: +61 3 9015 4761" class="phone_c">(+61) 3 9015 4761</a></h5>
                            </div>
                            <h3 class="wow" data-splitting>Visit Us.
                            </h3>
                            <div class="item">
                                <h6>Level 26/101,  
                                    <br/>Collins Street, Melbourne, VIC
                                </h6>
                            </div>
                            <div class="social mt-50">
                                <a target="_blank" href="https://www.facebook.com/inceptives/"><i
                                    class="fab fa-facebook-f"></i></a>
                            <a target="_blank" href="https://twitter.com/Inceptives1"><i
                                    class="fab fa-twitter"></i></a>
                            <a target="_blank" href="#0"><i class="fab fa-instagram"></i></a>
                            <a target="_blank" href="https://www.linkedin.com/company/theinceptives"><i
                                    class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
