import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import  Logo_design  from './Pages/Logo_design';
import "swiper/css";
import Web_design from './Pages/Web_design';
import Graphic_design from './Pages/Graphic_design';
import Seo from './Pages/Seo';
import Branding from './Pages/Branding';
import Digital_marketing from './Pages/Digital_marketing';
import Combo from './Pages/Combo';
import Contact from './Pages/Contact';

import Portfolio_page from './Pages/Portfolio_page';


function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/Logo_design' element={<Logo_design/>}/>
      <Route path='/Web_design' element={<Web_design/>}/>
      <Route path='/Graphic_design' element={<Graphic_design/>}/>
      <Route path='/Seo' element={<Seo/>}/>
      <Route path='/Branding' element={<Branding/>}/>
      <Route path='/Digital_marketing' element={<Digital_marketing/>}/>
      <Route path='/Combo' element={<Combo/>}/>
      <Route path='/Contact' element={<Contact/>}/>
      <Route path='/PortFolio' element={<Portfolio_page/>}/>
    </Routes>

    </>
  );
}
export default App;
