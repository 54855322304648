import React from 'react'

export default function Pricing_digital() {
  return (
    <>
        <section class="app-price section-padding">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-10">

                        <div class="sec-head  text-center">
                            <h6 class="wow fadeIn" data-wow-delay=".5s"> Pricing Package</h6>
                            <h3 class="wow color-font"> Pricing Package for Social Media Marketing & Kit</h3>

                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-10">

                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                                    aria-controls="home" aria-selected="true">Social Media Kit</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                                    aria-controls="profile" aria-selected="false">Social Media Marketing</a>
                            </li>

                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="pric-tables">
                                    <div class="row justify-content-center ">
                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Social Media Banner  <br class="d-none d-lg-block"/>
                                                      With Stationary </h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>$</span> 74.99 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/14k14k7vo0g94EwcNq" target="_blank"
                                                        class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">
                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Facebook Banner</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Twitter Banner</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Business Card Design</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Letterhead Design</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Envelope Design</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Dedicated Designer</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 3 Design Revisions</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Turnaround Time 24 - 48 Hours</div>
                                                        </li>
                                                        <li class="d-flex justify-content-center text-center">

                                                            <b>Features</b>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Satisfaction Guaranteed</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Ownership rights</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Unique Design Guarantee</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Money Back Guarantee</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> All Final File Formats</div>
                                                        </li>
                                                    </ul>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Social Media <br class="d-none d-lg-block"/> Posts +</h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>$</span> 139.99 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/6oE00g5ngd2V0og7t7" target="_blank"
                                                        class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">
                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 5 Social Media posts </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Flyer or Infographic </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Facebook Banner</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Twitter Banner</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Unique Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 6 Design concepts </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Dedicated Designer </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Dedicated Account Manager </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Unlimited Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Turnaround Time - 48 - 72 Hours </div>
                                                        </li>
                                                        <li class="d-flex justify-content-center text-center">

                                                            <b>Features</b>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Satisfaction Guaranteed </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Ownership rights </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Unique Design Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Money Back Guarantee </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> All Final File Formats </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Social Media <br class="d-none d-lg-block"/> Posts</h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>$</span> 134.99 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/8wMdR602Wd2V2woaFk" target="_blank"
                                                        class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">
                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 5 Social Media posts </div>
                                                        </li>
                                                    
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Facebook Banner</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Twitter Banner</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 1 Unique Design</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 4 Design concepts (Trifold / Bi-fold)</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Dedicated Designer</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Dedicated Account Manager</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Unlimited Revisions</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Turnaround Time - 48 - 72 Hours</div>
                                                        </li>
                                                        <li class="d-flex justify-content-center text-center">

                                                            <b>Features</b>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Satisfaction Guaranteed</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Ownership rights</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Unique Design Guarantee</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> 100% Money Back Guarantee</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> All Final File Formats</div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div class="pric-tables">
                                    <div class="pric-tables">
                                        <div class="row ">
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Startup <br class="d-none d-lg-block"/>
                                                            Package</h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>$</span> 399 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/9AQ14k9DwaUN6MEcNt"
                                                            target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div class="feat">

                                                        <ul class="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> 3 Social Media Channels</div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> (Facebook / Twitter / Instagram) </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Social Media Account Setup </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> 2 Posts Per Channel </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> 3 Design Revisions</div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> 2 Cover Photos</div>
                                                            </li>

                                                        </ul>


                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Basic <br class="d-none d-lg-block"/>
                                                            Package</h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>$</span> 799 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/14kdR63f8aUNdb228Q"
                                                            target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div class="feat">
                                                        <ul class="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> 2 Social Media Channels </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> (Facebook / Instagram) </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Social Media Account Setup </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Complete Account Management </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> 3 Posts Per Week (per network) </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Custom Editorial Calendar </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> End of Term Analytic Report </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Dedicated Account Manager </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> $100 worth of Paid Boosting on Facebook </div>
                                                            </li>

                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Plus<br class="d-none d-lg-block"/>
                                                            Package</h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>$</span> 960 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/bIYbIYg1U4wp6MEfZH"
                                                            target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div class="feat">
                                                        <ul class="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> 4 Social Media Channels </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> (Facebook / Twitter / GPlus / Instagram) </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Social Media Account Setup </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Complete Account Management </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> 4 Posts Per Week (per network) </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Custom Editorial Calendar </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Call to Action Integration </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Social Media Competitor Analysis </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> 1 Custom Blog Submission per Week </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Monthly Analytic Report </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> Dedicated Account Manager </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon"> <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg></i>
                                                                </div>
                                                                <div> $200 worth of Total Paid Boosting </div>
                                                            </li>

                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
                {/* <!-- <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="pric-tables">
                            <div class="row justify-content-center ">

                                <div class="col-lg-4 col-md-5">
                                    <div class="item active">
                                        <div class="type text-center mb-15">
                                            <h5>Stationery <br class="d-none d-lg-block"/>
                                                Package</h5>
                                        </div>
                                        <div class="amount text-center mb-40">
                                            <h3><span>$</span> 74.99 </h3>
                                        </div>
                                        <div class="order mb-40 text-center">
                                            <a href="https://buy.stripe.com/8wM3cs5ng6Exdb200m" target="_blank" class="butn-gr rounded buton">
                                                <span>Choose Package</span>
                                            </a>
                                        </div>
                                        <div class="feat">
                                            <ul class="scrollbar_pricing">
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 1 Business Card Design</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 1 Letterhead Design</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 1 Envelope Design</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> Dedicated Designer</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 3 Design Revisions</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> Turnaround Time 24 - 48 Hours</div>
                                                </li>
                                                <li class="d-flex justify-content-center text-center">

                                                    <b>Features</b>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Satisfaction Guaranteed</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Ownership rights</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Unique Design Guarantee</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Money Back Guarantee</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> All Final File Formats</div>
                                                </li>
                                            </ul>


                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-5">
                                    <div class="item active">
                                        <div class="type text-center mb-15">
                                            <h5>Infographic <br class="d-none d-lg-block"/>
                                                Package</h5>
                                        </div>
                                        <div class="amount text-center mb-40">
                                            <h3><span>$</span> 139.99 </h3>
                                        </div>
                                        <div class="order mb-40 text-center">
                                            <a href="https://buy.stripe.com/5kA00g7voe6Z1sk8wT" target="_blank" class="butn-gr rounded buton">
                                                <span>Choose Package</span>
                                            </a>
                                        </div>
                                        <div class="feat">
                                            <ul class="scrollbar_pricing">
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 1 Unique Design </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 6 Design concepts </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> Dedicated Designer </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> Dedicated Account Manager </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> Unlimited Revisions </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> Turnaround Time - 48 - 72 Hours </div>
                                                </li>
                                                <li class="d-flex justify-content-center text-center">

                                                    <b>Features</b>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Satisfaction Guaranteed </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Ownership rights </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Unique Design Guarantee </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Money Back Guarantee </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> All Final File Formats </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-5">
                                    <div class="item active">
                                        <div class="type text-center mb-15">
                                            <h5>Brochure/Flyer <br class="d-none d-lg-block"/>
                                                Package</h5>
                                        </div>
                                        <div class="amount text-center mb-40">
                                            <h3><span>$</span> 134.99 </h3>
                                        </div>
                                        <div class="order mb-40 text-center">
                                            <a href="https://buy.stripe.com/eVa3cs5ng4wpb2U4gE" target="_blank" class="butn-gr rounded buton">
                                                <span>Choose Package</span>
                                            </a>
                                        </div>
                                        <div class="feat">
                                            <ul class="scrollbar_pricing">
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 1 Unique Design</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 4 Design concepts (Trifold / Bi-fold)</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> Dedicated Designer</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> Dedicated Account Manager</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> Unlimited Revisions</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> Turnaround Time - 48 - 72 Hours</div>
                                                </li>
                                                <li class="d-flex justify-content-center text-center">

                                                    <b>Features</b>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Satisfaction Guaranteed</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Ownership rights</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Unique Design Guarantee</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> 100% Money Back Guarantee</div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <path
                                                                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                </path>
                                                            </svg></i>
                                                    </div>
                                                    <div> All Final File Formats</div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> --> */}
            </div>
        </section>  
    </>
  )
}
